<template>
  <v-container>
    <v-row v-if="showLogin" class="text-center">
      <v-col class="mb-4">
        <h1 class="heading-1 mb-3">
          Beyond Labz
        </h1>
        <div class="subtitle-1 mb-3">
          Admin, Sales and Support Portal
        </div>
        <v-container style="max-width: 480px">
          <v-card class="pa-4" flat>
            <v-form>
              <v-text-field label="Username" v-model="username"></v-text-field>
              <v-text-field label="Password" v-model="password" type="password"></v-text-field>
              <v-btn block color="primary" elevation="0" class="my-4" @click="login">Login</v-btn>
            </v-form>
          </v-card>
        </v-container>
        <p class="caption font-weight-regular">
          This page is for authorized users only, for help please contact your manager.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Login',
  components: {
  },
  data: () => ({
    username: '',
    password: ''
  }),
  computed: {
    ...mapGetters(['loggedIn', 'permissions']),
    ...mapState(['user']),
    showLogin () {
      return !this.loggedIn
    }
  },
  methods: {
    login () {
      this.$store.dispatch('login', { username: this.username, password: this.password })
    }
  },
  watch: {
    user () {
      if (this.user) {
        this.$router.push('/support')
      }
    }
  }
}
</script>

<style scoped>

</style>
