<template>
  <v-container>
    <v-container v-if="!searchMode" class="text-center mt-6">
      <v-list-item class="justify-center">
        <v-btn to="/support/user">Search Users</v-btn>
      </v-list-item>
      <v-list-item  class="justify-center">
        <v-btn to="/support/activation">Search Activation Codes</v-btn>
      </v-list-item>
    </v-container>
    <v-container v-else class="py-0 my-0">
      <v-card flat class="py-3 my-3">
        <v-card-subtitle class="text-capitalize font-weight-bold">Select {{ this.searchMode }}</v-card-subtitle>
        <v-autocomplete
          v-model="selectedItem"
          :items="searchItems"
          :search-input.sync="searchTerm"
          class="mx-4"
          flat
          hide-no-data
          hide-details
          :placeholder="'Find ' + searchMode"
          filled
        ></v-autocomplete>
      </v-card>
      <v-card flat class="py-3 my-3">
        <v-card-subtitle class="font-weight-bold">
          <span class="text-capitalize">{{ searchMode }} Details: </span>{{ selectedItem }}</v-card-subtitle>
        <v-row
          v-for="item in detailFields[searchMode]"
          :key="item.text"
          class="justify-center ma-0 pa-0"
        >
          <v-col class="pa-0">
            <v-subheader class="justify-end font-weight-bold pa-0 pb-2">{{item.text}}</v-subheader>
          </v-col>
          <v-col class="py-0">
            <v-text-field
              solo-inverted
              flat
              dense
              :value="details[item.value]"
              disabled
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat>
        <v-card-subtitle class="text-capitalize font-weight-bold">License Results</v-card-subtitle>
        <v-text-field
          v-model="filter"
          :append-icon="mdiMagnify"
          label="Filter Results"
          hide-details
          flat
          class="px-3"
        ></v-text-field>
        <v-data-table
          :headers="headers[searchMode]"
          :items="query_data"
          :search="filter"
          :footer-props="{
          'items-per-page-options': [10, 20, 50, -1]
        }"
          :options.sync="dataOptions"
          dense
        >
          <template v-if="searchMode === 'activation'" v-slot:item.username="{ item }">
            <v-btn
              v-if="item.username"
              :to="'/support/user/' + item.username"
              small
              text
              class="text-none"
            ><v-icon left small>{{ mdiOpenInNew }}</v-icon>{{ item.username }}
            </v-btn>
          </template>
          <template v-if="searchMode === 'user'" v-slot:item.activation_code="{ item }">
            <v-btn
              v-if="item.activation_code"
              :to="'/support/activation/' + item.activation_code"
              small
              text
              class="text-none"
            ><v-icon left small>{{ mdiOpenInNew }}</v-icon>{{ item.activation_code }}</v-btn>
          </template>
          <template v-slot:item.start_date="{ item }">
            <span>{{ formatDate(item.start_date) }}</span>
          </template>
          <template v-slot:item.end_date="{ item }">
            <span>{{ formatDate(item.end_date) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-container>
</template>
-
<script>
import { mdiMagnify, mdiOpenInNew } from '@mdi/js'

function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default {
  name: 'Support',
  data: () => ({
    selectedItem: '',
    searchMode: '',
    searchTerm: '',
    searchItems: [],
    searchLabel: {
      user: 'User Name',
      activation: 'Activation Code'
    },
    searchUrls: {
      user: (term) => `/api/users/search/${term}`,
      activation: (term) => `/api/activation_codes/search/${term}`
    },
    licenseUrls: {
      user: (term) => `/api/user_licenses/${term}`,
      activation: (term) => `/api/activation_code/${term}`
    },
    detailUrls: {
      user: (term) => `/api/user_details/${term}`,
      activation: (term) => `/api/activation_details/${term}`
    },
    detailFields: {
      user: [],
      activation: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Purchased',
          value: 'quantity'
        },
        {
          text: 'Consumed',
          value: 'consumed'
        },
        {
          text: 'Source',
          value: 'source'
        }
      ]
    },
    details: {},
    headers: {
      user: [
        { text: 'User Name', value: 'username' },
        { text: 'License', value: 'license' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'End Date', value: 'end_date' },
        { text: 'Activation Code', value: 'activation_code' },
        { text: 'License Source', value: 'license_source' }
      ],
      activation: [
        { text: 'User Name', value: 'username' },
        { text: 'License Count', value: 'license_count' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'End Date', value: 'end_date' }
      ]
    },
    filter: '',
    dataOptions: {
      page: 1
    },
    query_data: [],
    mdiMagnify: mdiMagnify,
    mdiOpenInNew: mdiOpenInNew
  }),
  methods: {
    selectItem () {
      if (this.searchMode && this.selectedItem) {
        const path = `/support/${this.searchMode}/${this.selectedItem}`
        if (path !== this.$route.path) {
          this.$router.push(`/support/${this.searchMode}/${this.selectedItem}`)
        }
      }
    },
    async updateQueryData () {
      this.searchMode = this.$route.params.mode
      this.searchTerm = this.$route.params.search
      this.selectedItem = this.$route.params.search
      this.dataOptions.page = 1

      if (this.searchMode && this.selectedItem) {
        const licenseUrl = this.licenseUrls[this.searchMode](this.selectedItem)
        fetch(licenseUrl)
          .then(res => res.json())
          .then(data => {
            this.query_data = data
          })

        const detailsUrl = this.detailUrls[this.searchMode](this.selectedItem)
        fetch(detailsUrl)
          .then(res => res.json())
          .then(data => {
            this.details = data ? data[0] : {}
          })
      } else {
        this.query_data = []
        this.details = {}
      }
      this.updateSearchItems()
    },
    updateSearchItems () {
      if (this.searchTerm) {
        const url = this.searchUrls[this.searchMode](this.searchTerm)
        fetch(url)
          .then(res => res.json())
          .then(data => {
            this.searchItems = data.map(x => x.code || x.username)
          })
      } else {
        this.searchItems = []
      }
    },
    formatDate (date) {
      return date ? date.substr(0, 10) : ''
    }
  },
  watch: {
    $route () {
      this.updateQueryData()
    },
    searchTerm: debounce(function () {
      this.updateSearchItems()
    }, 500),
    selectedItem () {
      this.selectItem()
    }
  },
  mounted () {
    this.updateQueryData()
  }
}
</script>
