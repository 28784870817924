<template>
  <v-container class="mt-6">
    <v-card flat>
      <v-toolbar dense flat>
        <v-toolbar-title>Activation Codes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="primary"
              v-bind="attrs"
              v-on="on"
            ><v-icon>{{ mdiPlus }}</v-icon>Create Code</v-btn>
          </template>
          <v-card class="pa-4">
            <v-form ref="createForm">
              <v-card-title>Create Activation Code</v-card-title>
              <v-text-field
                label="Code"
                v-model="activation_code"
                :rules="[() => !!activation_code.match(/^[0-9a-zA-Z_-]+$/) || 'Must contain only letters, numbers, hyphens and underscores.']"
              ></v-text-field>
              <v-text-field
                label="Quantity"
                v-model="quantity"
                :rules="[() => !isNaN(quantity) && parseInt(quantity) > 0 && parseInt(quantity) < 100000 || 'Must be a number between 1 and 100,000']"
              ></v-text-field>
              <v-select
                :items="activation_licenses"
                label="Duration"
                v-model="activation_license"
              ></v-select>
              <v-select
                :items="activation_products"
                label="Product"
                v-model="activation_product"
              ></v-select>
              <v-btn @click="createCode">Create Code</v-btn>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="filter"
        :append-icon="mdiMagnify"
        label="Filter Results"
        hide-details
        flat
        class="px-3"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="query_data"
        :search="filter"
        :footer-props="{
          'items-per-page-options': [50, -1]
        }"
        :options.sync="dataOptions"
        dense
      >
        <template v-slot:item.code="{ item }">
          <v-btn
            v-if="item.code"
            :to="'/support/activation/' + item.code"
            small
            text
            class="text-none"
          ><v-icon left small>{{ mdiOpenInNew }}</v-icon>{{ item.code }}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mdiMagnify, mdiOpenInNew, mdiPlus } from '@mdi/js'

export default {
  name: 'Sales',
  data: () => ({
    dialog: false,
    activation_code: '',
    quantity: 10,
    activation_license: '1 Year',
    activation_licenses: ['1 Year', '1 Month', '1 Week', '5 day', '3 Month', '6 Month'],
    activation_product: 'Beyond Labz CC',
    activation_products: ['Beyond Labz CC', 'ChemLab', 'Organic', 'Biology', 'Physics', 'Physical Science'],
    headers: [
      { text: 'Code', value: 'code' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Consumed', value: 'consumed' }
    ],
    filter: '',
    dataOptions: {
      page: 1
    },
    query_data: [],
    mdiMagnify: mdiMagnify,
    mdiOpenInNew: mdiOpenInNew,
    mdiPlus: mdiPlus
  }),
  methods: {
    createCode () {
      if (this.$refs.createForm.validate()) {
        const payload = {
          code: this.activation_code,
          quantity: parseInt(this.quantity),
          license_type: this.activation_licenses.indexOf(this.activation_license) + 1,
          product_id: this.activation_product.indexOf(this.activation_product) + 1
        }
        console.log('createCode ::', payload)
        fetch('/api/create_activation_code', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then(() => {
          this.updateActivationCodes()
          this.filter = this.activation_code
        }).catch(() => {
          console.log('Error creating activation code', payload)
        })
        this.dialog = false
      }
    },
    updateActivationCodes () {
      fetch('/api/portal_activation_codes')
        .then(res => res.json())
        .then(data => {
          this.query_data = data
        })
    }
  },
  mounted () {
    this.updateActivationCodes()
  }
}
</script>

<style scoped>

</style>
