var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-6"},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Activation Codes")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPlus))]),_vm._v("Create Code")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-form',{ref:"createForm"},[_c('v-card-title',[_vm._v("Create Activation Code")]),_c('v-text-field',{attrs:{"label":"Code","rules":[function () { return !!_vm.activation_code.match(/^[0-9a-zA-Z_-]+$/) || 'Must contain only letters, numbers, hyphens and underscores.'; }]},model:{value:(_vm.activation_code),callback:function ($$v) {_vm.activation_code=$$v},expression:"activation_code"}}),_c('v-text-field',{attrs:{"label":"Quantity","rules":[function () { return !isNaN(_vm.quantity) && parseInt(_vm.quantity) > 0 && parseInt(_vm.quantity) < 100000 || 'Must be a number between 1 and 100,000'; }]},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('v-select',{attrs:{"items":_vm.activation_licenses,"label":"Duration"},model:{value:(_vm.activation_license),callback:function ($$v) {_vm.activation_license=$$v},expression:"activation_license"}}),_c('v-select',{attrs:{"items":_vm.activation_products,"label":"Product"},model:{value:(_vm.activation_product),callback:function ($$v) {_vm.activation_product=$$v},expression:"activation_product"}}),_c('v-btn',{on:{"click":_vm.createCode}},[_vm._v("Create Code")])],1)],1)],1)],1),_c('v-text-field',{staticClass:"px-3",attrs:{"append-icon":_vm.mdiMagnify,"label":"Filter Results","hide-details":"","flat":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.query_data,"search":_vm.filter,"footer-props":{
        'items-per-page-options': [50, -1]
      },"options":_vm.dataOptions,"dense":""},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
      var item = ref.item;
return [(item.code)?_c('v-btn',{staticClass:"text-none",attrs:{"to":'/support/activation/' + item.code,"small":"","text":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))]),_vm._v(_vm._s(item.code))],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }